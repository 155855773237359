export const isEmailError = (el) => {
    const regRus = /^[а-яё.]+@[а-яё-]+\.[a-яё]{2,10}$/i;

    if (regRus.test(el.value)) {
        // errorBlock.html('email должен быть на английском');
        return true;
    }

    // errorBlock.html('указан не верный email');

    const reg = /^[a-z-._0-9]+@[a-z-._0-9]+\.[a-z0-9]{2,10}$/i;
    return !reg.test(el.value);
};

export const isEmailHasPermission = (el, errorBlock) => {
    const regRus = /^[a-z-._0-9-+]+@[a-z-._0-9]+\.[a-z0-9]{2,10}$/i;
    const value = el.value.trim();
    const elDomain = value.split('@')[1];

    const separateURL = window.location.href.split('/');
    const lastEndPoint = separateURL[separateURL.length - 1];

    if (!regRus.test(value) && value.length !== 0) {
        errorBlock.innerHTML = 'Неверный формат e-mail адреса. Адрес электронной почты должен выглядеть так: login@example.com';
        return true;
    }

    if (value[0] === '+' || value[0] === '-' || value[0] === '_') {
        errorBlock.innerHTML = 'Email не может содержать [+-_] в начале или в конце строки';
        return true;
    }

    if (value[value.length - 1] === '+' || value[value.length - 1] === '-' || value[value.length - 1] === '_') {
        errorBlock.innerHTML = 'Email не может содержать [+-_] в начале или в конце строки';
        return true;
    }

    if (value.length === 0) {
        errorBlock.innerHTML = 'Заполните поле';
        return true;
    }

    return false;
};

export const  isValidAddress = (el, options) => {
    const { errorBlock, type } = options;
    const reg = /^[а-яА-ЯЁё0-9 /,-.№N]+$/;
    const reg2 = /^([0-9]+)_([0-9]+)$/;
    const value = el.value.trim();
    const errAddressText = 'Некорректный адрес места жительства. Адрес места жительства может содержать только буквы, цифры и символы [/,-.№]';
    const errIssuedText = 'Поле "Кем выдан" может содержать только буквы, цифры и символы [/,-.№]';
    const errMessage = type === 'address' ? errAddressText : errIssuedText;

    if (value.length === 0) {
        errorBlock.innerHTML = 'Заполните поле';
        return true;
    }

    if (!reg.exec(value)) {
        errorBlock.innerHTML = errMessage;
        return true;
    }

    if (reg2.test(value)) {
        errorBlock.innerHTML = errMessage;
        return true;
    }
};

export const validateSnils = (snils) => {
    if (snils.length === 0) {
        return false;
    };

    if (snils.replace(/_/g,'').split(' ').join('').length < 11) {
        return true;
    };

    var sum = 0;
    for (var i = 0; i < 9; i++) {
        sum += parseInt(snils[i]) * (9 - i);
    }
    var checkDigit = 0;
    if (sum < 100) {
        checkDigit = sum;
    } else if (sum > 101) {
        checkDigit = parseInt(sum % 101);
        if (checkDigit === 100) {
            checkDigit = 0;
        }
    }
    if (checkDigit === parseInt(snils.slice(-2))) {
        return false;
    } else {
        return true;
    }
}

export const isPasswordError = (el) => {
    // console.log(555, el.value >= 3);
    return el.value <= 3;
};

export const isTextError = (el) => {
    return el.value <= 3;
};
