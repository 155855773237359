export default class Api {
    //Get запрос
    getRequest = async (url, method = 'GET') => {
        const res = await fetch(url, { method: method });
        return res.json();
    };

    //Post запрос
    sendFormData = async (url, body, ) => {
		var csrfToken = $('meta[name="csrf-token"]').attr("content");
        const res = await fetch(url, {
            method: 'POST',
            body: body,
			headers: {
				'_csrf': csrfToken
			}
        });
        return res.json();
    };

    sendProcessFinancialValidate = async (changesData) => {
        return await this.sendFormData(`/Process/Financial/Validate`, changesData);
    };

    sendAnswers = async (answerData) => {
        return await this.sendFormData(`/ajax/save-pop-up-statistic`, answerData);
    };

	sendQuest = async (answerData) => {
        return await this.sendFormData(`/hot-line-form`, answerData);
    };

	lifeinsuranceForm = async (data) => {
        return await this.sendFormData(`/lifeinsurance-form`, data);
    };

	sendZaemFormData = async (data) => {
        return await this.getRequest(`/policy/strahovanie_jizni_zaem`+ data);
    };

    resendCode = async () => {
        return await this.getRequest(`/Identity/ResendCode`);
    };

    getQuestions = async () => {
        return await this.getRequest(`/ajax/get-pop-up-questions`);
    };

	getPortfolios = async () => {
        return await this.getRequest(`/disclosure/get-portfolios`);
    };

	getParams = async () => {
        return await this.getRequest(`/disclosure/get-params`);
    };


    getProcessFinancialSendCode = async () => {
        return await this.getRequest(`/Process/Financial/SendCode`, 'POST');
    };

    getSearchResult = async (inputValue) => {
        return await this.getRequest(`https://sberbank-insurance.ru/search/autocomplete?query=${inputValue}`);
    };
}

